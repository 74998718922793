import { observer } from "mobx-react-lite";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { useScheduleStore } from "providers/RootStoreProvider";
import { Schedule, ScheduleType } from "interfaces/Schedule";
import { useTranslation } from "react-i18next";
import client from "services/api";
import { BuildingType } from "interfaces/Building";

interface NoDeskBookedTodayProps {
  schedule: Schedule;
}

const NoDeskBookedToday = observer((props: NoDeskBookedTodayProps) => {
  const schedule = props.schedule;
  const scheduleStore = useScheduleStore();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [capacity, setCapacity] = useState({
    planned_attendance: 0,
    total_capacity: -1,
    booked_attendance: 0,
  });

  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      if (schedule && schedule.office && schedule.office.building_type === BuildingType.OFFICE) {
        const res = await client.get(
          `/api/building/buildings/date/${moment(schedule.date).format(
            "YYYY-MM-DD"
          )}/capacity/?id=${schedule.office.id}`
        );
        const data = res.data.results[0];
        setCapacity(data);
      }
    })();
  }, [schedule]);

  useEffect(() => {
    if (
      schedule.status === ScheduleType.OFFICE &&
      !schedule.space &&
      capacity.total_capacity !== -1
    ) {
      scheduleStore.setNoDeskBookingToday(true);
      setShow(true);
    } else {
      scheduleStore.setNoDeskBookingToday(false);
      setShow(false);
    }
  }, [capacity]);

  const bookSpaceRequired = () =>
    history.push(`/booking/${moment(schedule.date).format("YYYY-MM-DD")}/${schedule!.office!.id}`);

  const changePlanner = () => history.push(`/my-planner`);

  return (
    <>
      {show && (
        <ConfirmDialog
          isOpen={true}
          title={t("Do you need a desk?")}
          primaryButtonText={t("Book a desk")}
          secondaryButtonText={t("Change My Planner")}
          handleSecondaryClick={changePlanner}
          handlePrimaryClick={bookSpaceRequired}
        >
          {t(
            "You have planned to attend the office today, but you haven't booked a desk. Do you need to book a desk?"
          )}
        </ConfirmDialog>
      )}
    </>
  );
});

export default NoDeskBookedToday;
