import { Dictionary } from "lodash";

export interface VisitorRegistration {
  id: number;
  first_name: string;
  last_name: string;
}

export enum CardType {
  "None" = 0,
  "External Visitor (pass required)" = 1,
  "Internal Visitor" = 2,
  "Short-Term employee / Contractor" = 3,
  "Employee / Embedded Contractor" = 4,
  "Special Access Area" = 5,
  "Replacement Card" = 6,
  "Child Visitor" = 7,
  "External Visitor (no pass required)" = 8,
  "VMS - External Visitor" = 9,
}

export enum RequestStatusChoices {
  "SUBMITTED" = 0,
  "APPROVED" = 1,
  "DENIED" = 2,
  "PROCESSING" = 3,
  "PENDING_APPROVAL" = 4,
  "PARTIALLY_APPROVED" = 5,
  "CARD_REQUESTED" = 6,
  "CARD_ASSIGNED" = 7,
  "CARD_DISBURSED" = 8,
}

export enum VmsMeetingStatusChoices {
  "SUBMITTED" = 0,
  "APPROVED" = 1,
  "DENIED" = 2,
  "PROCESSING" = 3,
  "PENDING_APPROVAL" = 4,
  "PARTIALLY_APPROVED" = 5,
  "CARD_REQUESTED" = 6,
  "CARD_ASSIGNED" = 7,
  "CARD_DISBURSED" = 8,
  "CANCELLED" = 9,
}

export interface VisitorRegistrationModel {
  location_id: number;
  email_id: string;
  from_date_time: string;
  to_date_time: string;
  first_name?: string;
  last_name?: string;
  space_id?: number;
  returning_visitor?: boolean;
  book_a_desk?: boolean;
  access_pass_needed?: boolean;
  access_area_ids?: string[];
  checked_in?: boolean;
  company?: string;
  phone_number?: string;
  reason_for_visit?: string;
  host_name_id?: number;
  notify_visitor?: boolean;
  requester_id?: number;
}

export interface BuildingAccessModel {
  id: number;
  card_type: number;
  start_date_time: string;
  end_date_time: string;
  access_hours?: string;
  access_reason?: string;
  access_areas?: string[];
  special_access?: boolean;
  photo?: string;
  reason_for_visit?: string;
  created_at?: string;
  request_status?: number;
  location: number;
  requester: number;
  card_holder?: number;
  supervisor?: number;
  host_name?: number;
}

export interface BuildingAcccessSpecialAccessModel {
  location_id: number;
  card_type_id: number;
  start_date_time: string;
  end_date_time: string;
  access_hours: number;
  requester_id: number;
  card_holder_id: number;
  supervisor_id: number;
  access_area_ids: string[];
  access_reason: string;
  special_access: boolean;
}

export interface BuildingAcccessShortTermEmployeeModel {
  location_id: number;
  card_type_id: number;
  start_date_time: string;
  end_date_time: string;
  access_hours: number;
  requester_id: number;
  card_holder_id: number;
  host_name_id: number;
  special_access?: boolean;
  access_area_ids: string[];
  access_reason: string;
  photo: any;
  additional_info: string;
  company?: string;
}
export interface BuildingAcccessEmployeeModel {
  location_id: number;
  card_type_id: number;
  start_date_time: string;
  end_date_time: string;
  access_hours: number;
  requester_id: number;
  card_holder_id: number;
  host_name_id: number;
  special_access?: boolean;
  access_area_ids: string[];
  access_reason: string;
  photo: any;
  additional_info: string;
  company?: string;
}
export interface BuildingAcccessReplacementCardModel {
  location_id: number;
  card_type_id: number;
  requester_id: number;
  card_holder_id: number;
  company?: string;
  reason_for_replacement?: string;
}
export interface BuildingAcccessInternalVisitorModel {
  location_id: number;
  card_type_id: number;
  start_date_time: string;
  end_date_time: string;
  access_hours: number;
  requester_id: number;
  card_holder_id: number;
  host_name_id: number;
  reason_for_visit?: string;
  special_access?: boolean;
  access_area_ids?: string[];
  access_reason?: string;
  book_a_desk?: boolean;
  space_id?: string;
  notify_visitor?: boolean;
}
export interface BuildingAcccessExternalVisitorModel {
  location_id: number;
  card_type_id: number;
  start_date_time: string;
  end_date_time: string;
  access_hours: number;
  requester_id: number;
  email_id: string;
  first_name?: string;
  last_name?: string;
  company?: string;
  phone_number?: string;
  host_name_id: number;
  preferred_floor_or_meeting_room_number?: string;
  reason_for_visit?: string;
  special_access?: boolean;
  access_area_ids?: string[];
  access_reason?: string;
  book_a_desk?: boolean;
  space_id?: string;
  notify_visitor?: boolean;
}

export interface GallagherVMSIntergationModel {
  location_id: number;
  card_type_id: number;
  start_date_time: string;
  end_date_time: string;
  requester_id: number;
  email_id: string[];
  first_name?: string[];
  last_name?: string[];
  company?: string[];
  phone_number?: string[];
  host_name_id: number;
  vms_access_area?: number;
  meeting_name: string;
  meeting_description?: string;
  notify_visitor: boolean;
  card_holder_mobile_cred?: boolean[];
  under18?: boolean[];
  meeting_room?: string;
  building_request_vms_id?: number;
}

export interface GallagherVMSIntergationUpdateModel {
  location_id: number;
  card_type_id: number;
  start_date_time: string;
  end_date_time: string;
  requester_id: number;
  card_holder: string[];
  card_holder_first_name?: string[];
  card_holder_last_name?: string[];
  card_holder_company?: string[];
  card_holder_phone_num?: string[];
  host_name_id: number;
  vms_access_area?: number;
  meeting_name: string;
  meeting_description?: string;
  notify_visitor: boolean;
  card_holder_mobile_cred?: boolean[];
  card_holder_under18?: boolean[];
  meeting_room?: string;
  building_request_vms_id?: number;
}
export interface BuildingAcccessVMSExternalVisitorModel {
  location_id: number;
  card_type_id: number;
  start_date_time: string;
  end_date_time: string;
  requester_id: number;
  email_id: string;
  first_name?: string;
  last_name?: string;
  company?: string;
  phone_number?: string;
  host_name_id: number;
  vms_access_area: number;
  meeting_name: string;
  meeting_description?: string;
  notify_visitor: boolean;
  mobile_credentials?: boolean;
  under18?: boolean;
  building_request_vms_id?: number;
}

export interface FormElement {
  fieldName: string;
  helperText: string;
  isRequired: boolean;
}

export interface BuildingAccessRequest_Approval_Items {
  status_id: number;
  created_at: Date;
  start_date_time: Date;
  end_date_time: Date;
  access_hours: number;
  requester_id: number;
  requester_first_name: string;
  requester_last_name: string;
  card_holder_id: number;
  card_holder_first_name: string;
  card_holder_last_name: string;
  access_reason: string;
  reason_for_visit?: string;
  location: number;
  location_id?: number;
  access_area_id: number[];
  building_request_id: number;
  requested_area_list_id: number[];
  host_name_id?: number;
  host_name_first_name?: string;
  host_name_last_name?: string;
  supervisor_id?: number;
  supervisor_first_name?: string;
  supervisor_last_name?: string;
  approver_id: number;
  approver_first_name: string;
  approver_last_name: string;
  access_area_name: string;
  comment: string;
  card_type: string;
}

export interface Vms_Meeting_Items {
  vms_visit_id: number;
  status_id: number;
  created_at: Date;
  location_name: string;
  location_id: number;
  card_type: number;
  start_date_time: Date;
  end_date_time: Date;
  requester_id: number;
  requester_first_name: string;
  requester_last_name: string;
  // location: number;
  host_name_id: number;
  host_name_first_name?: string;
  host_name_last_name?: string;
  host_email: string;
  email_id: string;
  first_name: string;
  last_name: string;
  company: string;
  phone_number: string;
  card_holder_mobile_cred: boolean;
  under18: boolean;
  meeting_description?: string;
  meeting_name: string;
  meeting_room: string;
  vms_access_area: string;
  vms_access_area_id: number;
}

export const getDefaultCardTypeBlurb = (typ: CardType) => {
  switch (+typ) {
    case CardType.None:
      return "";
    case CardType["Special Access Area"]:
      return `Applies to all requests to have access to special areas. 
Request must be submitted 3 days* prior to the visit, and:
 - Card Holder must have an access card for the Location
 - There must be reasonable justification for the access, which is 
   to be detailed in the request
 - Supervisor is required to ensure card is returned
      
*IROC requests must be submitted at least 2 weeks prior to the 
proposed start date`;
    case CardType["Short-Term employee / Contractor"]:
    case CardType["Employee / Embedded Contractor"]:
      return `Use this form to request a building access pass for BHP offices. 
      Please note that a BHP account will be required for all employees and contractors to complete this form. 
      If an account has not been provisioned yet, please select the Card Type 'External Visitor' until an account is available for a separate request. 
      Applies to all short-term employees and contractors. 
      Request must be submitted 3 days prior to the start date, and:
      <ul><li>A Resident Host is required to ensure card is returned</li>
      <li>Global Workplace Induction must be completed before a card is issued (this can be performed in LMS).</li></ul>`;
    case CardType["External Visitor (pass required)"]:
      return `Applies to all External Visitors and BHP employees that don’t yet have a BHP account (once an account has been provisioned then the applicable access request should be raised). An email address will be required for the visitor request. Request must be submitted 3 days* prior to the start date, and:
      <ul><li>Visitors must be hosted by a BHP employee or contractor at all 
      times</li>
      <li>Visit must be less than or equal to 7 calendar days</li>
      <li>Show evidence of vaccination status </li>
      <li>Host must ensure there is a virtual alternative to the visit</li>
      <li>Resident Host is required to ensure card is returned</li></ul>
      *IROC requests must be submitted at least <strong>2 weeks</strong> prior to the 
      proposed start date`;
    case CardType["Internal Visitor"]:
      return `Applies to all Internal Visitors. Request must be submitted <strong>3 days</strong> prior to the start date, and:
      <ul><li>Issued to non-residents who are BHP employees and contractors requiring access for up to 30 calendar days</li>
      <li>Resident Host is required to ensure card is returned</li>
      <li>Global Workplace Induction must be completed before a card is issued (this can be performed in LMS).</li></ul>`;
    case CardType["External Visitor (no pass required)"]:
      return `Applies to all External Visitors and BHP employees that don’t yet have a BHP account (once an account has been provisioned then the applicable access request should be raised). An email address will be required for the visitor request. Request must be submitted 3 days* prior to the start date, and:
      <ul><li>Visitors must be hosted by a BHP employee or contractor at all 
      times</li>
      <li>Visit must be less than or equal to 7 calendar days</li>
      <li>Show evidence of vaccination status </li>
      <li>Host must ensure there is a virtual alternative to the visit</li>
      <li>Resident Host is required to ensure card is returned</li></ul>
      *IROC requests must be submitted at least <strong>2 weeks</strong> prior to the 
      proposed start date`;
  }
};

export const getLocalDate = (value) => {
  if (!value) return "";
  const offset = new Date().getTimezoneOffset() * 1000 * 60;
  const offsetDate = new Date(value).valueOf() - offset;
  const date = new Date(offsetDate).toISOString();
  return date;
};
